import { IEffects, ISemanticColors, IPalette, loadTheme } from '@uifabric/styling'
import { initializeIcons } from '@uifabric/icons'

export const semanticColors: Partial<ISemanticColors> = {
  bodyDivider: 'black',
  bodyFrameBackground: '#2F333B',
  bodyStandoutBackground: '#25272A',
  bodyText: '#FEFEFE',
  bodySubtext: '#cdced2',
  buttonBackground: '#989A9F',
  buttonBackgroundHovered: '#A8AAAE',
  buttonBackgroundPressed: '#7A7C81',
  buttonText: '#3B3E46',
  buttonTextPressed: '#2C2E34',
  focusBorder: '#25272A',
  inputBackground: '#292B30',
  inputBorder: '#292B30',
  inputBorderHovered: 'transparent',
  inputIcon: '#CDCED2',
  inputPlaceholderText: '#757575',
  inputText: '#FFFFFF',
  inputTextHovered: '#FFFFFF',
  listBackground: '#2E3036',
  listHeaderBackgroundHovered: '#25272A',
  listHeaderBackgroundPressed: '#000000',
  menuHeader: '#171919',
  menuBackground: '#2e3036',
  menuItemBackgroundHovered: '#38393f',
  primaryButtonTextHovered: '#3B3E46',
  primaryButtonTextPressed: '#2C2E34',
}
//#323232

export const effects: Partial<IEffects> = {
  roundedCorner2: '10px',
}

export const palette: Partial<IPalette> = {
  neutralLighter: '#38393f',
  themePrimary: '#004e8c'
}

loadTheme({ effects, semanticColors, palette })

initializeIcons()
