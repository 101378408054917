import React from 'react'

import { AppIcon } from 'AppIcon/AppIcon'
import { keycloak } from 'Auth/Keycloak'
import { Header } from 'Header/Header'

import cst from '__assets/cst.svg'
import domino from '__assets/domino.svg'
import syncmatrix from '__assets/syncmatrix.svg'
import live from '__assets/live.svg'
import orca from '__assets/orca.svg'
import strzelec from '__assets/strzelec.png'


import krogan from '__assets/krogan.png'
import pvm from '__assets/pvm.png'
import showroom from '__assets/showroom.png'
import gdelt from '__assets/gdelt.png'

import orcanext from '__assets/orcanext.svg'
import cognext from '__assets/cognext.svg'


import './Home.css'

export function Home() {
  const { groups, email } = keycloak.tokenParsed

  function show(client: string) {
    if ((email.endsWith('@zoiclabs.com') || email.endsWith('@zoicstudios.com')) && client != 'strzelec') return true
    return groups.some((val: string) => val === `/${client}_access`)
  }

  return (
    <div className="Home">
      <Header />

      <div className="Home-row">
        {show('domino') && <AppIcon href="https://domino.zoiclabs.io" src={domino} alt="Domino" width="60" />}
        {show('cst') && <AppIcon href="https://cst.zoiclabs.io" src={cst} alt="CST" width="100" />}
        {show('cog-next') && <AppIcon href="https://cog-next.zoiclabs.io" src={cognext} alt="Cog Next" width="100" />}
        {show('synmatrix') && <AppIcon href="https://zyncmatrix.zoiclabs.io" src={syncmatrix} alt="Zync Matrix" width="120" />}
        {show('strzelec') && <AppIcon href="https://logistix.zoiclabs.io" src={strzelec} alt="Logistix" width="120" />}
        {show('orca') && <AppIcon href="https://orca.zoiclabs.io" src={orca} alt="Orca" width="120" />}
        {show('orca') && <AppIcon href="https://orca-next.zoiclabs.io" src={orcanext} alt="Orca Next" width="120" />}


        <AppIcon href="https://live.zoiclabs.io" src={live} alt="Live" width="100" />

        <AppIcon href="https://preview.showroom.fit" src={showroom} alt="Showroom" width="120" />
        <AppIcon href="https://proxymatch.io" src={pvm} alt="Proxy Match" width="100" />
        <AppIcon href="https://ppi.zoiclabs.io" src={krogan} alt="SARS CoV2" width="100" />
        <AppIcon href="https://gdeltsearch.zoiclabs.io" src={gdelt} alt="GDELT Search" width="100" />

      </div>
    </div>
  )
}
