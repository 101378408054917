import React from 'react'
import { render } from 'react-dom'

import { AuthProvider } from 'Auth/AuthProvider'
import { Home } from 'Home/Home'
import './Theme'

render(
  <React.StrictMode>
    <AuthProvider>
      <Home />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
