import * as React from 'react'
import { Callout, DirectionalHint, IconButton, Persona, PersonaSize, PersonaInitialsColor } from '@fluentui/react'

import { keycloak } from 'Auth/Keycloak'
import './Profile.css'

export function Profile() {
  let calloutTargetRef = React.useRef<any>()
  const [showProfile, setShowProfile] = React.useState<boolean>(false)
  const { given_name, family_name, email } = keycloak.tokenParsed

  return (
    <div>
      <div ref={calloutTargetRef}>
        <Persona
          initialsColor={PersonaInitialsColor.darkBlue}
          size={PersonaSize.size32}
          hidePersonaDetails={true}
          text={`${given_name} ${family_name}`}
          styles={{ root: { cursor: 'pointer' },  }}
          onClick={() => setShowProfile(!showProfile)}
        />
      </div>

      {showProfile && (
        <Callout
          isBeakVisible={false}
          gapSpace={10}
          target={calloutTargetRef.current}
          directionalHint={DirectionalHint.topRightEdge}
          styles={{
            root: { zIndex: 2 },
            calloutMain: { padding: 20, color: 'white' },
          }}
          onDismiss={() => setShowProfile(false)}>
          <Persona
            initialsColor={PersonaInitialsColor.darkBlue}
            size={PersonaSize.size40}
            text={`${given_name} ${family_name}`}
            secondaryText={email}
            styles={{ root: { marginBottom: 15, } }}
          />

          <div className="pointer" aria-label="Edit" onClick={keycloak.accountManagement}>
            <IconButton iconProps={{ iconName: 'Edit' }} styles={{ root: { fontSize: 12, marginRight: 5 } }} />
            <span>Edit</span>
          </div>

          <div className="pointer" aria-label="Logout" onClick={keycloak.logout}>
            <IconButton iconProps={{ iconName: 'SignOut' }} styles={{ root: { fontSize: 12, marginRight: 5 } }} />
            <span>Sign out</span>
          </div>
        </Callout>
      )}
    </div>
  )
}
