import Keycloak from 'keycloak-js'

export const keycloak: any = Keycloak({
  url: 'https://auth.zoiclabs.io/auth',
  realm: 'zoiclabs',
  clientId: 'home',
})

export const authenticate = () =>
  keycloak
    .init({
      //       onLoad: process.env.NODE_ENV === 'production' ? 'check-sso' : 'login-required',
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/check-sso.html`,
    })
    .then((isAuthenticated: boolean) => {
      if (!isAuthenticated) return keycloak.login()
      return {
        token: keycloak.token,
        error: '',
        isAuthenticated: true,
        user: {
          userId: keycloak.subject,
          username: keycloak.tokenParsed.preferred_username,
          firstName: keycloak.tokenParsed.given_name,
          lastName: keycloak.tokenParsed.family_name,
          email: keycloak.tokenParsed.email,
        },
      }
    })
    .catch((err: Error) => ({
      token: '',
      error: err.message,
      isAuthenticated: false,
      user: {},
    }))

keycloak.onTokenExpired = () => {
  keycloak.updateToken(5).catch(() => keycloak.login())
}
