import React from 'react'

import { Profile } from 'Profile/Profile'

import zoiclabs from '__assets/zoiclabs.png'
import './Header.css'

export function Header() {
  return (
    <div className="Header">
      <img src={zoiclabs} alt="Zoic Labs logo"/>
      <Profile />
    </div>
  )
}
