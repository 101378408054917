import React from 'react'
import './AppIcon.css'

interface IAppIconProps {
  href: string
  src: string
  alt: string
  width: string
}

export function AppIcon(props: IAppIconProps) {
  return (
    <a className="AppIcon" target="_blank" rel="noopener noreferrer" href={props.href} title={props.alt}>
      <div className="AppIcon-icon">
        <img src={props.src} alt={props.alt} width={props.width} />
        <div>{props.alt}</div>
      </div>
    </a>
  )
}
