import * as React from 'react'
import { authenticate } from 'Auth/Keycloak'

export interface IAuthState {
  token: string
  error: string
  isAuthenticated: boolean
  user: any
}

const authState: IAuthState = {
  token: '',
  error: '',
  isAuthenticated: false,
  user: {},
}

export const AuthContext = React.createContext(authState)

export function AuthProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [authInfo, setAuthInfo] = React.useState<IAuthState>(authState)

  React.useEffect(() => {
    authenticate().then((res: IAuthState) => {
      setAuthInfo(res)
    })
  }, [])

  return authInfo.isAuthenticated ? <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider> : null
}
